class API {
  constructor() {
    this.apiBase = `/api/v1/pages/${pageID}`;
  }

  static get instance() {
    if (API._instance === undefined)
      API._instance = new this();
    return API._instance;
  }

  static genQueryParams(params) {
    return Object
      .entries(params)
      .reduce((queryString, [key, value], index) => {
        return `${queryString}${index > 0 ? '&' : '?'}${key}=${encodeURIComponent(value)}`
      }, '');
  }

  static getCookie(name) {
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(`${name}=`));

    if (xsrfCookies.length === 0) {
      return null;
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }

  call(method, endpoint, params = {}, body = null) {
    let request = {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache': 'no-cache',
        'X-CSRFToken': API.getCookie('csrftoken'),
      },
      credentials: 'same-origin',
    };

    if (method.toUpperCase() !== 'HEAD'
      && method.toUpperCase() !== 'GET'
      && body != null) {
      request.body = JSON.stringify(body);
    }

    return fetch(`${this.apiBase}${endpoint}${API.genQueryParams(params)}`, request)
      .then(response => {
        if (!response.ok)
          return Promise.reject(response.json());
        return Promise.resolve(response.json());
      });
  }

  getFilterLevels(callback, language) {
    return this.call('GET', '/filter_levels/', {language});
  }

  getResources(callback, language) {
    return this.call('GET', '/resources/', {language});
  }

  getTimeslots(startDate, endDate, resourceId = null, toLocalTime = true) {
    let params = {
      start: startDate,
      end: endDate
    };

    if (resourceId !== null) {
      params.resource = resourceId;
    }

    return this
      .call('GET', '/timeslots/', params)
      .then((data) => {
        let allTimeslots = data;
        if (toLocalTime) {
          allTimeslots = Object
            .values(data)
            .reduce((timeslots, additional_timeslots) =>
              timeslots.concat(additional_timeslots), [])
            .reduce((timeslots, timeslot, index, all) => {
              let mStart = moment(timeslot.start);
              let mEnd = moment(timeslot.end);

              let date = mStart.format('YYYY-MM-DD');
              if (!timeslots.hasOwnProperty(date))
                timeslots[date] = [];

              timeslots[date].push({
                ...timeslot,
                start: mStart.format(),
                end: mEnd.format(),
              });

              return timeslots;
            }, {});
        }
        return allTimeslots;
      });
  }

  createReservation(
    resource_id, reservation_type_id, firstname, lastname, email,
    phone, startDate, endDate, company_name, custom_fields) {
    let body = {
      resource_id: resource_id,
      first_name: firstname,
      last_name: lastname,
      email: email,
      start_date: startDate,
      end_date: endDate,
      phone_number: phone,
    };

    if (company_name !== null)
      body.company = company_name;

    if (reservation_type_id !== null)
      body.reservation_type_id = reservation_type_id;

    if (custom_fields)
      body['custom_fields'] = custom_fields;

    return this.call('POST', '/reservations/', {}, body);
  }

  updateReservation(reservation_id, data) {
    return this.call('PATCH', `/reservations/${reservation_id}/`, {}, data);
  }

  createPayment(reservation_id, amount) {
    return this.call('POST', '/payment/', {}, {reservation_id, amount});
  }

  findAvailableResources(start, end, quantity) {
    return this.call('GET', '/resources/', {start, end, quantity});
  }
}
